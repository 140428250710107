<template>
  <v-dialog v-model="dialog" width="800" @click:outside="clickOutside">
    <template v-slot:activator="{ attrs }">
      <p id="goodsReceiptForm" v-bind="attrs" @click="openForm"></p>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5" style="margin: auto">FORMULIR PENERIMAAN</span>
      </v-card-title>
      <v-form
        v-if="form != null"
        ref="formGr"
        :disabled="loading"
        lazy-validation
        @submit.prevent="saveForm"
      >
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="4" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  PIC
                </p>

                <v-text-field
                  :value="form.employee.name"
                  disabled
                  dense
                  outlined
                  style="font-size: 12px; height: 45px"
                />
              </v-col>
              <v-col cols="12" md="4" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Perusahaan
                </p>

                <v-text-field
                  :value="form.company.name"
                  disabled
                  dense
                  outlined
                  style="font-size: 12px; height: 45px"
                />
              </v-col>
              <v-col cols="12" md="4" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Department
                </p>

                <v-text-field
                  :value="form.department.name"
                  disabled
                  dense
                  outlined
                  style="font-size: 12px; height: 45px"
                />
              </v-col>

              <v-col cols="12" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Diterima Dari
                </p>

                <v-autocomplete
                  v-model="form.vendor"
                  :items="dropdown.payreq"
                  :rules="rules.received_from_vendor_name"
                  item-text="vendor_name"
                  item-value="vendor_id"
                  style="font-size: 12px; height: 45px"
                  return-object
                  dense
                  outlined
                  clearable
                >
                  <template v-slot:item="data">
                    <template>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ data.item.vendor_name }}
                        </v-list-item-title>
                        <!-- <v-list-item-subtitle>
                          {{ data.item.document_no }}
                        </v-list-item-subtitle> -->
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
                <!-- <v-autocomplete
                  v-model="form.vendor"
                  :items="dropdown.vendor"
                  :rules="rules.received_from_vendor_name"
                  :search-input.sync="vendorSearch"
                  item-text="name"
                  item-value="id"
                  return-object
                  outlined
                  hide-details
                  hide-no-data
                  clearable
                  dense
                ></v-autocomplete> -->
              </v-col>
              <v-col cols="12" md="6" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Diserahkan Oleh
                </p>

                <v-text-field
                  v-model="form.submitted_by_name"
                  :rules="rules.submitted_by_name"
                  dense
                  outlined
                  clearable
                  style="font-size: 12px; height: 45px"
                />
              </v-col>
              <v-col cols="12" md="6" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Diterima Oleh
                </p>

                <v-text-field
                  v-model="form.received_by_name"
                  :rules="rules.received_by_name"
                  dense
                  outlined
                  clearable
                  style="font-size: 12px; height: 45px"
                />
              </v-col>
              <v-col cols="12" md="4" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Tgl. Terima
                </p>

                <v-text-field
                  v-model="form.received_date"
                  :rules="rules.received_date"
                  dense
                  type="date"
                  name="startdate"
                  step="1"
                  outlined
                  clearable
                  style="font-size: 12px; height: 45px"
                />
              </v-col>
              <v-col cols="12" md="4" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  No. Surat Jalan Vendor
                </p>

                <v-text-field
                  v-model="form.sj_no"
                  dense
                  outlined
                  style="font-size: 12px; height: 45px"
                />
              </v-col>
              <!-- <v-col cols="12" md="4" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  No. Terima
                </p>

                <v-text-field
                  v-model="form.received_no"
                  dense
                  outlined
                  style="font-size: 12px; height: 45px"
                />
              </v-col> -->
              <v-col cols="12" md="4" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  No. FPB
                </p>

                <v-text-field
                  :value="form.purchase_request_no"
                  dense
                  outlined
                  disabled
                  style="font-size: 12px; height: 45px"
                />
              </v-col>
              <v-col cols="12" md="4" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  No. PO
                </p>

                <v-text-field
                  v-model="form.po_no"
                  dense
                  outlined
                  disabled
                  style="font-size: 12px; height: 45px"
                />
              </v-col>

              <!-- <v-col cols="12" md="4" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  No. Form
                </p>

                <v-text-field
                  v-model="form.form_no"
                  dense
                  outlined
                  style="font-size: 12px; height: 45px"
                />
              </v-col> -->
            </v-row>

            <div style="margin-top: 30px">
              <v-data-table
                max-height="50vh"
                mobile-breakpoint="0"
                style="cursor: pointer; border: 1px solid rgba(0, 0, 0, 0.2)"
                :headers="grHeaders"
                :items="form.goods_receipt_detail"
                :items-per-page="10"
                item-key="id"
              >
                <!-- <template v-slot:[`item.no`]="{ index }">
                  <div style="width: 35px">
                    {{ index + 1 }}
                  </div>
                </template> -->
                <template v-slot:[`item.action`]="{ item, index }">
                  <div style="width: 35px; font-size: 12px">
                    <v-btn
                      color="error"
                      class="ma-1 white--text"
                      x-small
                      @click="itemDelete(item, index)"
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on" x-small class="">
                            mdi-delete
                          </v-icon>
                        </template>
                        <span>Delete Item</span>
                      </v-tooltip>
                    </v-btn>
                  </div>
                </template>

                <template v-slot:[`item.payreq_no`]="{ item }">
                  <div style="width: 155px; font-size: 12px">
                    {{ item.payreq_no }}
                  </div>
                </template>
                <template v-slot:[`item.product_code`]="{ item }">
                  <div style="width: 95px; font-size: 12px">
                    {{ item.product_code }}
                  </div>
                </template>
                <template v-slot:[`item.product_name`]="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        v-bind="attrs"
                        v-on="on"
                        style="
                          text-align: left;
                          width: 250px;
                          font-size: 12px;
                          padding: 0;
                        "
                      >
                        {{
                          `${
                            item.product_code !== null
                              ? '(' + item.product_code + ')'
                              : ''
                          } ${item.product_name}`
                        }}
                      </div>
                    </template>
                    <span>
                      {{
                        `${
                          item.product_code !== null
                            ? '(' + item.product_code + ')'
                            : ''
                        } ${item.product_name}`
                      }}</span
                    >
                  </v-tooltip>
                </template>
                <template v-slot:[`item.qty_demand`]="{ item }">
                  <div style="width: 45px; font-size: 12px">
                    {{ item.qty_demand }}
                  </div>
                </template>
                <template v-slot:[`item.qty_paid`]="{ item }">
                  <div style="width: 45px; font-size: 12px">
                    {{ item.qty_paid }}
                  </div>
                </template>
                <template v-slot:[`item.qty_received`]="{ item }">
                  <div style="width: 45px; font-size: 12px">
                    {{ item.qty_received }}
                  </div>
                </template>
                <template v-slot:[`item.qty_done`]="{ item }"
                  ><div
                    style="
                      width: 95px;
                      height: 45px;
                      font-size: 12px;
                      margin-top: 3px;
                    "
                  >
                    <v-text-field
                      v-model="item.qty_done"
                      type="number"
                      :min="0"
                      :max="(item.qty_paid || 0) - (item.qty_received || 0)"
                      dense
                      outlined
                      style="margin: 0"
                    />
                  </div>
                </template>
                <template v-slot:[`item.uom_name`]="{ item }">
                  <div style="width: 45px; font-size: 12px">
                    {{ item.uom_name }}
                  </div>
                </template>
                <template v-slot:[`item.description`]="{ item }"
                  ><div
                    style="
                      width: 215px;
                      height: 45px;
                      font-size: 12px;
                      margin-top: 3px;
                    "
                  >
                    <v-text-field
                      v-model="item.description"
                      dense
                      outlined
                      style="margin: 0"
                    />
                  </div>
                </template>
              </v-data-table>
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <div style="padding: 0 25px 20px; width: 100%">
            <v-btn
              type="submit"
              style="width: 100%"
              color="blue darken-1"
              class="white--text"
              :loading="loading"
            >
              SIMPAN
            </v-btn>
            <v-btn
              style="width: 100%; margin-top: 15px"
              color="pink"
              class="white--text"
              @click="closeDialog"
              :loading="loading"
            >
              BATAL
            </v-btn>
          </div>
          <!-- <v-btn color="blue darken-1" text type="submit"> Save </v-btn> -->
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import buildType from '../../../services/buildType'
export default {
  name: 'FormGr',
  props: ['detail', 'selectedData'],
  data() {
    return {
      proc: buildType.apiURL('proc'),
      loading: false,
      dialog: false,
      form: null,
      dropdown: {
        payreq: []
      },
      // vendorSearch: null,
      selectedVendor: null,
      rules: {
        received_date: [],
        received_from_vendor_name: [],
        received_by: [],
        received_by_name: [],
        submitted_by_name: [],
        vendor: []
      }
    }
  },
  watch: {
    // vendorSearch(val) {
    //   if (val !== '') {
    //     val && val !== this.selectedVendor && this.getVendorDropdown(val)
    //   } else {
    //     this.selectedVendor = null
    //     this.dropdown.vendor = []
    //   }
    // }
  },
  computed: {
    ...mapGetters(['getUserProfile']),
    grHeaders() {
      const arrHeaders = [
        // {
        //   text: 'No',
        //   value: 'no',
        //   align: 'left'
        //   // sortable: true,
        // },
        {
          text: '',
          value: 'action',
          align: 'left'
          // sortable: true,
        },
        // {
        //   text: 'No. Payreq',
        //   value: 'payreq_no',
        //   align: 'left'
        //   // sortable: false,
        // },
        {
          text: 'Kode Barang',
          value: 'product_code',
          align: 'left'
          // sortable: false,
        },
        {
          text: 'Nama Barang',
          value: 'product_name',
          align: 'left'
          // sortable: false,
        },
        {
          text: 'Qty Request',
          value: 'qty_demand',
          align: 'left',
          // sortable: true,
          sortable: false
        },
        {
          text: 'Qty Dibayar',
          value: 'qty_paid',
          align: 'left',
          // sortable: true,
          sortable: false
        },
        // {
        //   text: 'Qty sisa',
        //   value: 'qty_left',
        //   align: 'left',
        //   // sortable: true,
        //   sortable: false
        // },
        // {
        //   text: 'Qty Kembali',
        //   value: 'qty_return',
        //   align: 'left',
        //   // sortable: true,
        //   sortable: false
        // },
        {
          text: 'Qty Diterima',
          value: 'qty_received',
          align: 'left',
          // sortable: true,
          sortable: false
        },
        {
          text: 'Penerimaan',
          value: 'qty_done',
          align: 'left',
          // sortable: true,
          sortable: false
        },
        {
          text: 'Satuan',
          value: 'uom_name',
          align: 'left',
          // sortable: true,
          sortable: false
        },
        {
          text: 'Keterangan',
          value: 'description',
          align: 'left',
          // sortable: true,
          sortable: false
        }
      ]
      // if (this.detail.status.id === 0) {
      return arrHeaders
      // }
    }
  },
  mounted() {},
  methods: {
    onResize() {
      this.wWidth = window.innerWidth
    },

    showMsgDialog(
      pModalType,
      pStatusMsg,
      pBtnCancel,
      pInputType,
      pInputAtt,
      yesBtn,
      noBtn,
      pText
    ) {
      const title =
        pInputType === 'file'
          ? 'Upload bukti approvement pada kolom dibawah'
          : ''

      let additional = ''
      if (pText !== undefined) {
        additional = '<span style="font-size:12px;">' + pText + '</span>'
      }
      return new Promise((resolve) => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html:
              '<strong style="font-size:18px;">' +
              pStatusMsg +
              '</strong>' +
              '<br/>' +
              additional,
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            input: pInputType !== undefined ? pInputType : null,
            inputLabel: pInputType !== undefined ? title : null,
            inputAttributes: pInputAtt !== undefined ? pInputAtt : null,
            confirmButtonText: yesBtn || 'Yes',
            cancelButtonText: noBtn || 'No'
          })
          .then((r) => {
            resolve(r)
          })
      })
    },
    // payreqWatcher(val) {
    //   if (this.form != null) {
    //     if (val != null) {
    //       // this.form.received_from_vendor_name = val.vendor_name
    //       this.form.payment_request_no = val.document_no
    //       this.form.goods_receipt_detail = []
    //       // this.getPayreqDetailItem()
    //     } else {
    //       // this.form.received_from_vendor_name = null
    //       this.form.payment_request_no = null
    //       this.form.goods_receipt_detail = []
    //     }
    //   }
    // },
    openForm() {
      console.log('hallo world')
      const arrProductId = []
      const arrDetails = []
      const objForm = {
        act: 'add_batch_in_item',
        purchase_request_id: this.detail.id,
        employee: {
          id: this.getUserProfile.employee.id,
          name: this.getUserProfile.employee.name
        },
        company: {
          id: this.getUserProfile.employee.company.id,
          name: this.getUserProfile.employee.company.name,
          code: this.getUserProfile.employee.company.alias
        },
        department: {
          id: this.getUserProfile.employee.department.id,
          name: this.getUserProfile.employee.department.name
        },
        purchase_request_no: this.detail.request_no,
        receipt_type: 1,
        received_date: null,
        vendor: null,
        received_by_name: null,
        submitted_by_name: null,
        // received_no: null,
        // form_no: null,
        sj_no: null,
        po_no: null,
        description: null,
        payment_request: null
      }

      for (let i = 0; i < this.selectedData.length; i++) {
        arrProductId.push(this.selectedData[i].product.id)
        arrDetails.push({
          // payreq_no: res.data.data.document_no,
          // payment_request_id:
          //   res.data.data.payment_request_detail[i]
          //     .payment_request_id,
          prd_id: this.selectedData[i].clear_id,
          product_id: this.selectedData[i].product.id,
          product_name: this.selectedData[i].product.name,
          product_code: this.selectedData[i].product.code,
          uom_id: this.selectedData[i].uom_id,
          uom_name: this.selectedData[i].uom,
          qty_demand: this.selectedData[i].qty,
          // qty_request: this.selectedData[i].qty_request,
          qty_paid: this.selectedData[i].qty_paid || 0,
          // qty_return: res.data.data.payment_request_detail[i].uom_id,
          qty_received: this.selectedData[i].qty_done || 0,
          qty_done: Number(0),
          description: null
        })
      }

      Object.assign(objForm, {
        goods_receipt_detail: arrDetails
      })
      this.getPayreqVendorDropdown(arrProductId)

      this.form = objForm
      setTimeout(() => {
        // this.startWatcher()
        this.dialog = true
      }, 300)
    },
    // clear() {
    //   this.$emit('loadPage')
    // },
    clickOutside() {
      this.dialog = true
      setTimeout(() => {
        this.form = null
      }, 300)
    },
    closeDialog() {
      this.dialog = false
      setTimeout(() => {
        this.form = null
        this.$emit('closeFormGr', 'close')
      }, 200)
      setTimeout(() => {
        this.dialog = false
      }, 200)
    },
    // getVendorDropdown(v) {
    //   // console.log(this.detail)

    //   const url = `${this.proc}vendor/dropdown?keyword=${v}`
    //   // url = url + '&order_by=prd.id&order_type=ASC'
    //   axios
    //     .get(url)
    //     .then((res) => {
    //       if (res.data.status_code == '00') {
    //         this.dropdown.vendor = res.data.data
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err)
    //     })
    // },
    getPayreqVendorDropdown(arrProductId) {
      console.log('arrProductId', arrProductId)
      const url = `${this.proc}payreq/dropdown_vendor?purchase_request_id=${this.detail.id}&product_id=[${arrProductId}]&status=[1,2,3]`
      // url = url + '&order_by=prd.id&order_type=ASC'
      axios
        .get(url)
        .then((res) => {
          console.log('getVendorDropdown', res)

          if (res.data.status_code == '00') {
            this.dropdown.payreq = res.data.data
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    getPayreqDetailItem() {
      const url = `${this.proc}payreq/detail/item?pr_id=${this.form.payment_request.id}&vendor_id=${this.form.payment_request.id}`
      // url = url + '&order_by=prd.id&order_type=ASC'
      axios
        .get(url)
        .then((res) => {
          console.log('payreq detail item>>>', res)
          if (res.data.status_code == '00') {
            if (res.data.data.payment_request_detail != null) {
              if (res.data.data.status == 3) {
                for (
                  let i = 0;
                  i < res.data.data.payment_request_detail.length;
                  i++
                ) {
                  const obj = {
                    payreq_no: res.data.data.document_no,
                    payment_request_id:
                      res.data.data.payment_request_detail[i]
                        .payment_request_id,
                    prd_id: res.data.data.payment_request_detail[i].prd_id,
                    product_id:
                      res.data.data.payment_request_detail[i].product_id,
                    product_code:
                      res.data.data.payment_request_detail[i].product_code,
                    product_name:
                      res.data.data.payment_request_detail[i].product_name,
                    uom_id: res.data.data.payment_request_detail[i].uom_id,
                    uom_name: res.data.data.payment_request_detail[i].uom_name,
                    qty_demand:
                      res.data.data.payment_request_detail[i].qty_demand,
                    qty_request:
                      res.data.data.payment_request_detail[i].qty_request,
                    // qty_return: res.data.data.payment_request_detail[i].uom_id,
                    qty_done: 0,
                    description: null
                  }
                  this.form.goods_receipt_detail.push(obj)
                }
              } else {
                this.showMsgDialog(
                  'warning',
                  'Tidak dapat digunakan, Payment request belum dibayar',
                  false
                )
                // this.form.received_from_vendor_name = null
                this.form.payment_request_no = null
                this.form.payment_request = null
              }
            }
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    saveForm() {
      this.rules.received_from_vendor_name = [(v) => !!v || 'Wajib diisi']
      this.rules.received_date = [(v) => !!v || 'Wajib diisi']
      this.rules.received_by_name = [(v) => !!v || 'Wajib diisi']
      this.rules.submitted_by_name = [(v) => !!v || 'Wajib diisi']
      // this.rules.payment_type = [(v) => !!v || 'Wajib diisi']
      const self = this
      setTimeout(function () {
        if (self.$refs.formGr.validate()) {
          self.createNewForm()
        }
      })
    },
    createNewForm() {
      const newForm = {
        act: this.form.act,
        purchase_request_id: this.form.purchase_request_id,
        employee_id: this.form.employee.id,
        employee_name: this.form.employee.name,
        company_id: this.form.company.id,
        company_code: this.form.company.code,
        company_name: this.form.company.name,
        department_id: this.form.department.id,
        department_name: this.form.department.name,
        // received_no: this.form.received_no,
        received_from_vendor_name: this.form.vendor.vendor_name,
        received_from_vendor_id: this.form.vendor.vendor_id,
        received_from_vendor_code: this.form.vendor.vendor_code,
        submitted_by_name: this.form.submitted_by_name,
        received_by_name: this.form.received_by_name,
        received_date: this.form.received_date,
        // form_no: this.form.form_no,
        sj_no: this.form.sj_no,
        goods_receipt_detail: this.form.goods_receipt_detail
      }
      if (this.form.po_no != null) {
        Object.assign(newForm, {
          po_no: this.form.po_no
        })
      }
      console.log(newForm)
      const xItem = this.form.goods_receipt_detail.find(
        ({ qty_paid, qty_received, qty_done }) =>
          qty_done > qty_paid - qty_received || qty_paid - qty_received == 0
      )
      if (xItem != undefined) {
        if (xItem.qty_paid - xItem.qty_received == 0) {
          this.showMsgDialog(
            'warning',
            `[${
              xItem.product_code
            }] Tidak dapat diproses karna QTY tersedia = ${
              xItem.qty_paid - xItem.qty_received
            }`,
            false
          )
        } else if (xItem.qty_paid - xItem.qty_received < xItem.qty_done) {
          this.showMsgDialog(
            'warning',
            `[${xItem.product_code}] Tidak dapat diproses, QTY diterima ${
              xItem.qty_done
            } melebihi QTY dibayar ${xItem.qty_paid - xItem.qty_received}`,
            false
          )
        }
      } else {
        let xValueLess = 0
        for (let i = 0; i < this.form.goods_receipt_detail.length; i++) {
          if (
            this.form.goods_receipt_detail[i].qty_done <
            this.form.goods_receipt_detail[i].qty_paid -
              this.form.goods_receipt_detail[i].qty_received
          ) {
            xValueLess += 1
          }
        }

        this.showMsgDialog(
          `${xValueLess > 0 ? 'warning' : 'question'}`,
          `${
            xValueLess > 0
              ? 'Ada item dengan jumlah penerimaan kurang dari qty dibayar'
              : 'Anda yakin ingin membuat penerimaan ?'
          }`,
          true,
          undefined,
          undefined,
          'Lanjutkan',
          'Batal',
          ''
        ).then((res) => {
          if (res.isConfirmed) {
            this.submitGr(newForm)
          }
        })
      }
    },
    async submitGr(form) {
      this.loading = true
      await axios
        .post(`${this.proc}gr/save`, form)
        .then((res) => {
          this.loading = false
          if (res.data.status_code == '00') {
            const created_id = res.data.created_id
            console.log(created_id)
            this.showMsgDialog('success', res.data.status_msg, false)
            this.closeDialog()
            setTimeout(() => {
              // this.$store.commit('setFpbId', created_id)
              this.$router.push(
                `/e-catalogue/goodsreceipt/detail/${created_id}?idx=0`
              )
            }, 100)
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'warning',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('warning', res.data.status_msg, false)
            }
          }
        })
        .catch((err) => {
          console.log(err)
          this.loading = false
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
        })
    },
    itemDelete(item, index) {
      // const itemIndex = this.form.goods_receipt_detail.indexOf(item)
      this.form.goods_receipt_detail.splice(index, 1)
      // console.log(index, itemIndex)
    }
  }
}
</script>
